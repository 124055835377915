<template>
  <div
    class="wrapper"
    v-loading="loading">
    <div v-if="hasDevice">
      <div class="list_box f_left">
        <div class="title">ENCODER LIST</div>
        <div class="search-box">
          <el-input
            v-model="search"
            size="small "
            placeholder="PeerID or name"
            suffix-icon="el-icon-search"
            @keyup.enter.native="searchEncoderList">
          </el-input>
          <i
            class="icon-zoom"
            @click="searchEncoderList"></i>
        </div>
        <div
          class="list"
          v-if="rpsDeviceList.length">
          <div
            class="item"
            v-for="item in rpsDeviceList"
            :key="item.id"
            @click="selectEncoderItem(item)"
            :class="[item.id == choosedRpsId ? 'choosed-item' : '' ]">
            <span :class="['status', item.status == '0' ? '' : item.status == '2' ? 'online-status' : 'live-status']"></span>
            <span class="device">{{item.name}}</span>
          </div>
        </div>
        <div
          class="list"
          v-else>
          <div class="no-search">"No device to match"</div>
        </div>
      </div>
      <div
        class="rps-box f_right"
        v-if="choosedRpsId">
        <iframe
          width="100%"
          height="100%"
          :src="rpsURL"
          frameborder="0"
          ref="iframe"></iframe>
      </div>
      <div
        v-else
        class="hint">
        <span>Please choose a device</span>
      </div>
    </div>
    <div
      v-else
      class="hint">
      "You don't have a device for rps"
    </div>
  </div>
</template>
<script>
import Outil from '@/assets/js/utils.js'
import Qs from 'qs'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      search: '',
      rpsDeviceList: [],
      rpsURL: '',
      choosedRpsId: '',
      loading: false,
      hasDevice: false,
      peerIds: []
    }
  },
  created () {
    const { protocol, href } = window.location
    if (protocol === 'https:') {
      const URL = href.replace('https:', 'http:')
      window.location.href = URL
    }
    this.loading = true
    this.getEncoderList()
  },
  destroyed () {
    clearInterval(this.timer)
    if (this.$createWebsocket.pageRequest) {
      this.$createWebsocket.pageRequest('stop', 'taskInfo_T_S') // 停止websocket
    }
  },
  computed: {
    ...mapState({
      pageRequest: state => state.websocket.pageRequest
    })
  },
  methods: {
    // 检索
    searchEncoderList () {
      this.getEncoderList()
      this.hasDevice = true
    },
    // 实时查询设备的状态
    getDeviceStatus () {
      const data = {
        type: 'taskInfo',
        module: 'taskInfo_T_S',
        data: { peerIds: this.peerIds.join(',') }
      }
      if (this.$createWebsocket.pageRequest) {
        this.$createWebsocket.pageRequest('stop', 'taskInfo_T_S') // 停止websocket
      }
      this.pageRequest.send({
        message: data,
        time: 1000,
        key: 'taskInfo_T_S',
        success: res => {
          const { result } = res
          if (result) this.rpsDeviceList = result
        }
      })
    },
    // 获取设备的列表
    getEncoderList () {
      const params = {
        condition: this.search,
        living: true,
        online: true,
        offline: true,
        pack: true,
        receiver: true
      }
      const paramsStr = Qs.stringify(params)
      this.axios.get('/ccp/tvucc-device/userDevice/listRPSDevice?' + paramsStr)
        .then(res => {
          this.loading = false
          const { errorCode, result } = res.data
          if (errorCode === '0x0' && result) {
            // 获取设备的PeerId
            this.getPeerIds(result)
            this.getDeviceStatus()
            this.hasDevice = true
          } else {
            this.hasDevice = false
            this.pageRequest.stop()
          }
        }).catch(() => {})
    },
    // 获取设备的PeerId
    getPeerIds (deviceList) {
      this.peerIds = deviceList.reduce((prev, next) => {
        prev.push(next.peerId)
        return prev
      }, [])
    },
    selectEncoderItem (item) {
      const { id, status, peerId, type } = item
      if (this.choosedRpsId === id) return
      if (status === '0') {
        this.$message({
          message: 'Device not available',
          type: 'warning'
        })
        return
      }
      this.rpsURL = ''
      this.choosedRpsId = id
      this.$nextTick(() => {
        this.rpsURL = `http://${this.$store.state.common.baseUrl.switchUrl}/configT.html#?tsession=${localStorage.getItem('NewCCsession')}&peerid=${Outil.encryption(peerId)}&rps=${type}`
      })
    }
  }
}
</script>
<style>
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #cccccc !important;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #cccccc !important;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #cccccc !important;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #cccccc !important;
}
</style>
<style lang='less' scoped>
.wrapper {
  overflow: hidden;
}
.list_box {
  width: 300px;
  height: calc(100vh - 80px);
  text-align: left;
  padding: 20px;
  background-color: #666;
  box-sizing: border-box;
}
.title {
  font-size: 20px;
  padding: 0px 0 30px 0;
  border-bottom: solid 1px rgba(255, 255, 255, 0.3);
}
.search-box {
  padding: 30px 0px 50px 0px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.3);
  position: relative;
}
.icon-zoom {
  position: absolute;
  top: 30px;
  right: 0;
  width: 30px;
  height: 32px;
  cursor: pointer;
}
::v-deep .el-input {
  font-size: 14px;
}
.list {
  padding: 20px 10px;
  .no-search {
    text-align: center;
    line-height: 10;
  }
  .item {
    line-height: 30px;
    margin-bottom: 10px;
    padding: 0px 10px;
    cursor: pointer;
  }
  .item:hover {
    background-color: rgba(51, 171, 79, 0.5);
  }
  .choosed-item {
    background-color: rgba(51, 171, 79, 0.5);
  }
  .status {
    display: inline-block;
    width: 9px;
    height: 9px;
    background-color: rgb(153, 153, 153);
    vertical-align: baseline;
    margin-right: 15px;
    // border-radius: 50%;
  }
  .live-status {
    background-color: rgb(51, 171, 79);
  }
  .online-status {
    background-color: #f80000;
  }
  .device {
    vertical-align: baseline;
  }
}
.rps-box {
  width: calc(100vw - 306px);
  height: auto;
  iframe {
    min-height: calc(100vh - 80px);
  }
}
.hint {
  line-height: 30;
  font-size: 24px;
}
</style>
